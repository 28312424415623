<template>
  <div>
    <div v-if="isShow == 0">
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item>
          <el-breadcrumb-item>水站统计</el-breadcrumb-item>
        </el-breadcrumb>
        <el-button type="success" style="height: 36px" @click="reload">
          <i style="font-size: 18px" class="el-icon-refresh-right"></i>
        </el-button>
      </div>

      <div class="tableInput">
        <span style="font-size: 14px">选择水站：</span>
        <el-select v-model="selectStation" clearable filterable placeholder="选择水站"
          style="width: 15% !important; margin: 0 5px">
          <el-option v-for="item in selectStationes" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>
        <div style="display: flex">
          <el-button type="success" icon="el-icon-search">查询</el-button>
          <el-button type="primary" icon="iconfont icon-daochu">导出报表</el-button>
        </div>
      </div>
      <div class="table">
        <div style="padding: 10px 0">
          <el-table ref="multipleTable" border :data="tableData" tooltip-effect="dark"
            style="width: 100%; border: 1px solid #e5e5e5">
            <el-table-column prop="id" label="编号" align="center">
            </el-table-column>
            <el-table-column prop="enterprise_name" label="水站名称" align="center">
            </el-table-column>
            <el-table-column prop="person_charge" label="联系人" align="center">
            </el-table-column>
            <el-table-column label="欠款" align="center">
              <template slot-scope="scope">
                <div style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  ">
                  <span style="font-size: 14px">{{ scope.row.debt }}</span>
                  <i class="iconfont icon-bianji" style="margin-right: 10px"
                    @click="handleDialogTitle(scope.row.name, scope.row.debt)"></i>
                  <i class="iconfont icon-sousuo" @click="isShowClick1(scope.row.name, '欠款详情')"></i>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="欠桶" align="center">
              <template slot-scope="scope">
                <div style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  ">
                  <span>{{ scope.row.underbarrel }}</span>
                  <i class="iconfont icon-sousuo" @click="isShowClick1(scope.row.name, '桶详情')"></i>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="押桶" align="center">
              <template slot-scope="scope">
                <div style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  ">
                  <span>{{ scope.row.cask }}</span>
                  <i class="iconfont icon-sousuo" @click="isShowClick1(scope.row.name, '桶详情')"></i>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="押桶金" align="center">
              <template slot-scope="scope">
                <div style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  ">
                  <span>{{ scope.row.caskMoney }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="预购库存" align="center">
              <template slot-scope="scope">
                <div style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  ">
                  <span>{{ scope.row.inventory }}</span>
                  <i class="iconfont icon-bianji" @click="isShowClick2(scope.row.name, 1)"></i>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="优惠券1" align="center">
              <template slot-scope="scope">
                <div style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  ">
                  <span>{{ scope.row.coupon1 }}</span>
                  <i class="iconfont icon-bianji" @click="isShowClick2(scope.row.name, 2)"></i>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="优惠券2" align="center">
              <template slot-scope="scope">
                <div style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  ">
                  <span>{{ scope.row.coupon2 }}</span>
                  <i class="iconfont icon-bianji" @click="isShowClick2(scope.row.name, 3)"></i>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="优惠券3" align="center">
              <template slot-scope="scope">
                <div style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 12px;
                  ">
                  <span>{{ scope.row.coupon3 }}</span>
                  <i class="iconfont icon-bianji" @click="isShowClick2(scope.row.name, 4)"></i>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!-- <div class="tableBottom">
            <span v-if="isResult" style="">显示第&nbsp;1&nbsp;至&nbsp;{{
              tableFinshNum
            }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span>
            <span v-if="!isResult" style="">显示&nbsp;{{ tableFinshNum }}&nbsp;项结果，共&nbsp;{{
              tableFinshNum
            }}&nbsp;项</span>
            <div style="
                display: flex;
                justify-content: flex-end;
                align-items: center;
              ">
              <el-button>首页</el-button>
              <el-button>上页</el-button>
              <el-pagination background layout=" pager,slot" :total="1"></el-pagination>
              <el-button>下页</el-button>
              <el-button>末页</el-button>
            </div>
          </div> -->
          <pagination :handleQuery="handleQuery" :currentPage="query.currentPage" :currentLength="query.currentLength"
            :total="total">
          </pagination>
        </div>
      </div>
      <el-dialog :title="`修改&quot;${dialogTitle}&quot;欠款金额`" :visible.sync="dialogVisible" width="30%"
        :before-close="handleClose">
        <div>
          <p>欠款：</p>
          <el-input v-model="dialogDebtVal" style="margin: 5px 0 15px"></el-input>
          <p>备注：</p>
          <el-input type="textarea" v-model="dialogRemarkVal" style="margin: 5px 0 15px"></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleDialogT">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <search-operate1 v-if="isShow == 1" @close="close_add" :searchBread1="searchBread1"></search-operate1>

    <search-operate2 v-if="isShow == 2" @close="close_add" :searchBread2="searchBread2"
      :identifier="identifier"></search-operate2>
  </div>
</template>
<script>
import pagination from "@/components/pagination/index.vue";
export default {
  components: {
    pagination,
    searchOperate1: () => import("./childrens/waterAtationAssets_search1.vue"),
    searchOperate2: () => import("./childrens/waterAtationAssets_search2.vue"),
  },
  data() {
    return {
      total: 0,
      query: {
        currentPage: 1,
        currentLength: 20,
      },
      isShow: 0,

      selectStation: "",
      selectStationes: [],

      tableData: [],
      isResult: false,
      tableFinshNum: 0,

      dialogVisible: false,
      dialogTitle: "",
      dialogDebtVal: "",
      dialogRemarkVal: "",

      searchBread1: {
        value1: "",
        value2: "",
      },
      searchBread2: "",
      identifier: -1,
    };
  },
  async mounted() {
    let res = (await this.$http.post("/Client/commercial_owner/lst")).data.data;
    this.selectStationes = res.map(el => el.enterprise_name);
    this.handleQuery()
    if (this.tableData.length == 0) {
      this.isResult = false;
    } else {
      this.isResult = true;
      this.tableFinshNum = this.tableData.length;
    }
  },
  computed: {},
  methods: {
    async handleQuery(page = 1) {
      this.query.currentPage = page;
      let res = (await this.$http.post("/Station/Barrel_Entry/property", this.query)).data;
      this.total = res.count;
      this.tableData = res.data;
    },
    reload() {
      location.reload();
    },
    close_add(data) {
      this.isShow = data;
    },
    handleEdit() { },
    handleDelete() { },

    handleDialogTitle(name, debt) {
      this.dialogVisible = true;
      this.dialogTitle = name;
      this.dialogDebtVal = debt;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleDialogT() {
      this.dialogVisible = false;
    },
    isShowClick1(val1, val2) {
      this.searchBread1.value1 = val1;
      this.searchBread1.value2 = val2;
      this.isShow = 1;
    },
    isShowClick2(val, i) {
      this.searchBread2 = val;
      this.identifier = i;
      this.isShow = 2;
    },
  },
};
</script>
<style lang="less" scoped>
.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tableInput {
  height: 31px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
}

.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}

.table {
  padding: 20px;

  span {
    font-size: 14px;
    margin-right: 5px;
  }

  i {
    cursor: pointer;
    color: #5a98de;
  }

  i:hover {
    text-decoration: underline;
    color: #0066cc;
  }
}
</style>
